// @flow
import React, {type ElementConfig} from 'react';
import classnames from 'classnames';

import {withStyles} from 'ui';
import CheckCircle from 'icons/CheckCircle';
import {type ClassesFromFn} from 'types';

const styles = theme => ({
  root: {
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: '0.05em',
    borderColor: 'currentColor',
  },
  icon: {
    color: 'currentColor',
  },
});

// see `CheckCircle` for supported props
type Props = {|
  ...ElementConfig<typeof CheckCircle>,

  // injected
  classes: ClassesFromFn<typeof styles>,
|};

function CheckCircleOutline({
  classes,
  className,
  iconClassName,
  ...props
}: Props) {
  return (
    <CheckCircle
      className={classnames(className, classes.root)}
      iconClassName={classnames(iconClassName, classes.icon)}
      {...props}
    />
  );
}

export default withStyles(styles)(CheckCircleOutline);
