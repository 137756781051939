//@flow
import React, {type ElementConfig} from 'react';
import classnames from 'classnames';

import {Icon, withStyles} from 'ui';
import {type ClassesFromFn} from 'types';

const styles = theme => ({
  root: {
    minWidth: '1em',
    minHeight: '1em',
  },
});

export const onboardingIconNames = {
  'file-zipped-vice': true,
  'file-zipped-new': true,
  'file-notes-document': true,
  'file-tasks-close': true,
  'file-notes-new': true,
  'file-tasks-subtract': true,
  'file-tasks-checklist': true,
  'file-tasks-add': true,
  'file-office-graph': true,
  'folder-close': true,
  'folder-check': true,
  'file-office-text': true,
  'folder-subtract': true,
  'folder-add': true,
  'file-swap': true,
  'files-3': true,
  'files-2': true,
  'files-1': true,
  'file-landscape-new-1': true,
  'file-hand': true,
  'file-new-2': true,
  'file-copy': true,
  'file-bookmark': true,
  'file-new-1': true,
  baby: true,
  'baby-hanging-toy': true,
  'romance-relationship': true,
  'romance-love-target': true,
  'romance-bow': true,
  'baby-trolley': true,
  'video-games-pacman': true,
  'video-games-pacman-ghost': true,
  'video-games-gameboy': true,
  'leisure-rest': true,
  'leisure-marry-go-round': true,
  'leisure-dj-booth': true,
  underwear: true,
  undershirt: true,
  'polo-shirt': true,
  'leisure-magic': true,
  'kitchen-pan-fry': true,
  'kitchen-frying-machine': true,
  'hat-magician': true,
  'hat-1': true,
  'kitchen-blender': true,
  'food-icecream-2': true,
  'chef-hat': true,
  'food-steak': true,
  'food-noodle-2': true,
  'food-chicken-drum-stick': true,
  'food-plate-knife-fork': true,
  'food-double-burger': true,
  'glass-cocktail-2': true,
  'espresso-machine': true,
  'coffee-mug': true,
  'health-prescription-2': true,
  'health-medicine-bottle': true,
  'health-hospital-sign-1': true,
  'health-heart-pulse': true,
  'health-graph-1': true,
  'health-ambulance': true,
  tank: true,
  'fire-extinguisher': true,
  'cc-camera-1': true,
  'hanging-rope': true,
  'hand-gun': true,
  'fire-lighter': true,
  'match-stick': true,
  'lamp-studio-1': true,
  'lamp-1': true,
  'treasure-chest-open': true,
  'toilet-roll': true,
  'spray-bottle': true,
  'pocket-knife': true,
  'tools-measuring-tape': true,
  backpack: true,
  'hammer-anvil': true,
  'fence-caution': true,
  couch: true,
  'chair-1': true,
  'star-constellation': true,
  'ring-planet': true,
  alien: true,
  tram: true,
  'motorcycle-2': true,
  'alien-head': true,
  'motorcycle-1': true,
  'monster-truck-1': true,
  'car-2': true,
  'bus-2': true,
  airplane: true,
  'sport-takraw': true,
  'sport-bowling': true,
  'hot-air-balloon': true,
  helicopter: true,
  'sport-football-field': true,
  'sport-dumbbell-1': true,
  'sport-basketball': true,
  'rechargable-battery': true,
  'nuclear-power-plant': true,
  'nature-flower-1': true,
  'eco-field': true,
  'eco-factory': true,
  'eco-car': true,
  'water-droplet': true,
  'save-water': true,
  'nature-plant-1': true,
  'water-tap': true,
  'spa-bamboo': true,
  'spa-lotus-flower': true,
  'spa-lotion': true,
  'beauty-lipstick': true,
  'beauty-hand-soap': true,
  'hotel-toilet-seat': true,
  'hotel-shower': true,
  'hotel-bed-1': true,
  'sign-toilet': true,
  'sign-elevator': true,
  'hotel-bath-shower': true,
  'travel-flippers': true,
  'travel-camping': true,
  'travel-beach': true,
  'travel-bag-1': true,
  'travel-globe': true,
  'places-colosseum': true,
  'places-christ-the-redeemer': true,
  'places-taj-mahal': true,
  'places-eiffel-tower': true,
  'water-tower': true,
  volcanoe: true,
  'building-barn': true,
  'temple-2': true,
  'places-water-well': true,
  'greek-column': true,
  'places-warehouse-1': true,
  'castle-1': true,
  'building-6': true,
  'building-10': true,
  'places-home-4': true,
  'places-home-3': true,
  'location-pin-skull-2': true,
  'location-pin-check-2': true,
  'location-pin-bank-2': true,
  'location-pin-subtract-2': true,
  'location-pin-close-2': true,
  'location-pin-add-2': true,
  'location-user': true,
  'location-pin-4': true,
  'location-map-1': true,
  'location-gps-on-2': true,
  window: true,
  'window-programming': true,
  android: true,
  'login-wrong': true,
  'login-lock': true,
  'login-check': true,
  'bitcoin-windows': true,
  'bitcoin-network': true,
  'bitcoin-laptop': true,
  'bitcoin-cloud': true,
  wallet: true,
  'coin-stack-1': true,
  'coin-purse-1': true,
  'trolley-box-service': true,
  'box-check': true,
  'box-subtract': true,
  'box-add': true,
  'box-2': true,
  'warehouse-box': true,
  'box-handle-1': true,
  'banking-spendings-3': true,
  'banking-donation-2': true,
  'banking-spendings-2': true,
  'banking-spendings-1': true,
  'business-whiteboard': true,
  'banking-debit-machine': true,
  safe: true,
  'gold-nuggets': true,
  'bank-note': true,
  'shopping-basket-subtract': true,
  'shopping-basket-close': true,
  'shopping-basket-check': true,
  'business-briefcase-cash': true,
  'shopping-basket-add': true,
  'shopping-basket-2': true,
  'shopping-basket-1': true,
  'register-machine': true,
  'receipt-1': true,
  'smiley-worry': true,
  'graduation-hat': true,
  dna: true,
  'beaker-science': true,
  'smiley-shy-1': true,
  'smiley-dolar': true,
  'smiley-poker-face': true,
  'smiley-wink': true,
  'smiley-smile-2': true,
  sunny: true,
  'wind-flag': true,
  'umbrella-open': true,
  'speaker-volume-decrease': true,
  'speaker-volume-off': true,
  'speaker-volume-increase': true,
  'speaker-volume-medium': true,
  'speaker-volume-high': true,
  'music-note-1': true,
  gramophone: true,
  'user-headphone': true,
  'video-control-record': true,
  'video-control-play': true,
  'video-control-stop': true,
  'video-control-pause': true,
  'video-control-eject': true,
  'video-control-previous': true,
  'video-control-next': true,
  'video-control-rewind': true,
  'video-control-fast-forward': true,
  'video-camera-3': true,
  'video-clip-4': true,
  'video-clip-3': true,
  'exposure-level': true,
  'picture-layer-1': true,
  'photo-portrait': true,
  'photo-landscape': true,
  'camera-live-view-off': true,
  'camera-1': true,
  projector: true,
  'paper-pin': true,
  'origami-paper-bird': true,
  'crop-image': true,
  'vector-triangle': true,
  'vector-square-1': true,
  'vector-line': true,
  'vector-circle': true,
  'send-to-front': true,
  'send-to-back': true,
  'remove-circle-1': true,
  'pencil-ruler': true,
  'pen-tablet': true,
  'paint-brush-1': true,
  'organization-hierarchy-3': true,
  'color-palette': true,
  'subtract-circle-1': true,
  'server-close-1': true,
  'add-circle-1': true,
  'server-subtract-1': true,
  'server-check-1': true,
  'server-bug': true,
  'server-add-1': true,
  'server-2': true,
  'synchronize-2': true,
  'synchronize-1': true,
  'server-network-1': true,
  cloud: true,
  'cloud-harddisk-1': true,
  'upload-computer': true,
  'download-computer': true,
  'data-upload-5': true,
  'data-download-5': true,
  'share-signal-user': true,
  'network-laptop': true,
  'network-business': true,
  'share-radar': true,
  'share-megaphone-2': true,
  'vote-thumbs-down': true,
  'lock-unlock-1': true,
  'lock-1': true,
  'vote-thumbs-up': true,
  'vote-heart-circle-1': true,
  'rewards-trophy-5': true,
  'rewards-pedestal': true,
  'rewards-banner-check': true,
  'vote-plus-one': true,
  'rewards-medal-1': true,
  'rewards-gift': true,
  'content-bookmark-4': true,
  'content-book-6': true,
  'navigation-next-1': true,
  'navigation-before-1': true,
  'cursor-touch-1': true,
  'content-book-2': true,
  'video-call-mobile-phone': true,
  'cursor-arrow-2': true,
  'cursor-arrow-1': true,
  'video-call-1': true,
  'smart-watch-circle-navigation': true,
  'hand-remote': true,
  devices: true,
  'battery-charging-1': true,
  'mobile-phone-audio': true,
  'battery-low': true,
  'battery-high': true,
  'mobile-phone-close-1': true,
  'mobile-phone-check-1': true,
  'phone-outgoing': true,
  'phone-incoming': true,
  'mobile-phone-off': true,
  'telephone-1': true,
  'laptop-1': true,
  keyboard: true,
  'computer-screen-1': true,
  'computer-personal-1': true,
  'chat-bubble-square-close': true,
  'chat-bubble-square-subtract': true,
  'chat-bubble-square-smiley': true,
  'chat-bubble-square-check': true,
  'chat-bubble-square-add': true,
  'chat-bubble-square-1': true,
  'police-officer-1': true,
  king: true,
  'female-1': true,
  'chef-1': true,
  'user-subtract': true,
  'user-check': true,
  'operator-male': true,
  'user-magnify': true,
  'user-add': true,
  'user-male': true,
  'user-heart': true,
  'user-female': true,
  'user-chat-1': true,
  'id-card-1': true,
  'email-timeout': true,
  'email-heart': true,
  'email-outbox': true,
  'email-inbox': true,
  outbox: true,
  'mail-refresh-1': true,
  inbox: true,
  'envelope-1': true,
  'at-sign': true,
  paperplane: true,
  hourglass: true,
  'calendar-1': true,
  'key-hole-1': true,
  'cog-box': true,
  'switch-right': true,
  'switch-left': true,
  'settings-1': true,
  close: true,
  'check-box': true,
  'pencil-2': true,
  binoculars: true,
  flash: true,
  'flash-off': true,
  'pin-2': true,
  scissors: true,
  quill: true,
  bin: true,
  'atomic-bomb': true,
};

export type OnboardingIconName = $Keys<typeof onboardingIconNames>;

type Props = {|
  children: OnboardingIconName,
  color?: $PropertyType<ElementConfig<typeof Icon>, 'color'>,
  className?: string,
  classes: ClassesFromFn<typeof styles>,
|};

function OnboardingIcon({children, color, className, classes}: Props) {
  return (
    <Icon
      color={color}
      className={classnames(className, `icon-${children}`, classes.root)}
    />
  );
}

export default withStyles(styles)(OnboardingIcon);
