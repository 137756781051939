// @flow
import React from 'react';
import classnames from 'classnames';
import DoneIcon from '@material-ui/icons/Done';

import {withStyles} from 'ui';
import {type ClassesFromFn} from 'types';
import {colors} from 'theme';

const styles = theme => ({
  root: {
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    width: '1em',
    height: '1em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&$inherit': {
      color: 'inherit',
    },
    '&$primary': {
      color: theme.palette.primary.main,
    },
    '&$secondary': {
      color: theme.palette.secondary.main,
    },
    '&$action': {
      color: theme.palette.action.active,
    },
    '&$error': {
      color: theme.palette.error.main,
    },
    '&$disabled': {
      color: theme.palette.text.disabled,
    },
  },
  icon: {
    color: colors.white,
    fontSize: '0.66em',
  },
  inherit: {},
  primary: {},
  secondary: {},
  action: {},
  error: {},
  disabled: {},
});

type Props = {|
  color: 'inherit' | 'primary' | 'secondary' | 'action' | 'error' | 'disabled',
  className?: string,
  iconClassName?: string,

  // injected
  classes: ClassesFromFn<typeof styles>,
|};

function CheckCircle({color, className, iconClassName, classes}: Props) {
  return (
    <div
      className={classnames(classes.root, className, {
        [classes.inherit]: color === 'inherit',
        [classes.primary]: color === 'primary',
        [classes.secondary]: color === 'secondary',
        [classes.action]: color === 'action',
        [classes.error]: color === 'error',
        [classes.disabled]: color === 'disabled',
      })}>
      <DoneIcon
        className={classnames(classes.icon, iconClassName)}
        color={color}
      />
    </div>
  );
}

CheckCircle.defaultProps = {
  color: 'inherit',
};

export default withStyles(styles)(CheckCircle);
