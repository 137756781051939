//@flow
import React, {type ElementConfig} from 'react';

import {Icon} from 'ui';

export const materialIconNames = {
  '3d_rotation': true,
  accessibility: true,
  accessibility_new: true,
  accessible: true,
  accessible_forward: true,
  account_balance: true,
  account_balance_wallet: true,
  account_box: true,
  account_circle: true,
  add_shopping_cart: true,
  alarm: true,
  alarm_add: true,
  alarm_off: true,
  alarm_on: true,
  all_inbox: true,
  all_out: true,
  android: true,
  announcement: true,
  arrow_right_alt: true,
  aspect_ratio: true,
  assessment: true,
  assignment: true,
  assignment_ind: true,
  assignment_late: true,
  assignment_return: true,
  assignment_returned: true,
  assignment_turned_in: true,
  autorenew: true,
  backup: true,
  book: true,
  bookmark: true,
  bookmark_border: true,
  bookmarks: true,
  bug_report: true,
  build: true,
  cached: true,
  calendar_today: true,
  calendar_view_day: true,
  camera_enhance: true,
  card_giftcard: true,
  card_membership: true,
  card_travel: true,
  change_history: true,
  check_circle: true,
  check_circle_outline: true,
  chrome_reader_mode: true,
  class: true,
  code: true,
  commute: true,
  compare_arrows: true,
  contact_support: true,
  copyright: true,
  credit_card: true,
  dashboard: true,
  date_range: true,
  delete: true,
  delete_forever: true,
  delete_outline: true,
  description: true,
  dns: true,
  done: true,
  done_all: true,
  done_outline: true,
  donut_large: true,
  donut_small: true,
  drag_indicator: true,
  eject: true,
  euro_symbol: true,
  event: true,
  event_seat: true,
  exit_to_app: true,
  explore: true,
  explore_off: true,
  extension: true,
  face: true,
  favorite: true,
  favorite_border: true,
  feedback: true,
  find_in_page: true,
  find_replace: true,
  fingerprint: true,
  flight_land: true,
  flight_takeoff: true,
  flip_to_back: true,
  flip_to_front: true,
  g_translate: true,
  gavel: true,
  get_app: true,
  gif: true,
  grade: true,
  group_work: true,
  help: true,
  help_outline: true,
  highlight_off: true,
  history: true,
  home: true,
  horizontal_split: true,
  hourglass_empty: true,
  hourglass_full: true,
  http: true,
  https: true,
  important_devices: true,
  info: true,
  input: true,
  invert_colors: true,
  label: true,
  label_important: true,
  label_off: true,
  language: true,
  launch: true,
  line_style: true,
  line_weight: true,
  list: true,
  lock: true,
  lock_open: true,
  loyalty: true,
  markunread_mailbox: true,
  maximize: true,
  minimize: true,
  motorcycle: true,
  note_add: true,
  offline_bolt: true,
  offline_pin: true,
  opacity: true,
  open_in_browser: true,
  open_in_new: true,
  open_with: true,
  pageview: true,
  pan_tool: true,
  payment: true,
  perm_camera_mic: true,
  perm_contact_calendar: true,
  perm_data_setting: true,
  perm_device_information: true,
  perm_identity: true,
  perm_media: true,
  perm_phone_msg: true,
  perm_scan_wifi: true,
  pets: true,
  picture_in_picture: true,
  picture_in_picture_alt: true,
  play_for_work: true,
  polymer: true,
  power_settings_new: true,
  pregnant_woman: true,
  print: true,
  query_builder: true,
  question_answer: true,
  receipt: true,
  record_voice_over: true,
  redeem: true,
  remove_shopping_cart: true,
  reorder: true,
  report_problem: true,
  restore: true,
  restore_from_trash: true,
  restore_page: true,
  room: true,
  rounded_corner: true,
  rowing: true,
  schedule: true,
  search: true,
  settings: true,
  settings_applications: true,
  settings_backup_restore: true,
  settings_bluetooth: true,
  settings_brightness: true,
  settings_cell: true,
  settings_ethernet: true,
  settings_input_antenna: true,
  settings_input_component: true,
  settings_input_composite: true,
  settings_input_hdmi: true,
  settings_input_svideo: true,
  settings_overscan: true,
  settings_phone: true,
  settings_power: true,
  settings_remote: true,
  settings_voice: true,
  shop: true,
  shop_two: true,
  shopping_basket: true,
  shopping_cart: true,
  speaker_notes: true,
  speaker_notes_off: true,
  spellcheck: true,
  star_rate: true,
  stars: true,
  store: true,
  subject: true,
  supervised_user_circle: true,
  supervisor_account: true,
  swap_horiz: true,
  swap_horizontal_circle: true,
  swap_vert: true,
  swap_vertical_circle: true,
  tab: true,
  tab_unselected: true,
  text_rotate_up: true,
  text_rotate_vertical: true,
  text_rotation_down: true,
  text_rotation_none: true,
  theaters: true,
  thumb_down: true,
  thumb_up: true,
  thumbs_up_down: true,
  timeline: true,
  toc: true,
  today: true,
  toll: true,
  touch_app: true,
  track_changes: true,
  translate: true,
  trending_down: true,
  trending_flat: true,
  trending_up: true,
  turned_in: true,
  turned_in_not: true,
  update: true,
  verified_user: true,
  vertical_split: true,
  view_agenda: true,
  view_array: true,
  view_carousel: true,
  view_column: true,
  view_day: true,
  view_headline: true,
  view_list: true,
  view_module: true,
  view_quilt: true,
  view_stream: true,
  view_week: true,
  visibility: true,
  visibility_off: true,
  voice_over_off: true,
  watch_later: true,
  work: true,
  work_off: true,
  work_outline: true,
  youtube_searched_for: true,
  zoom_in: true,
  zoom_out: true,
  add_alert: true,
  error: true,
  error_outline: true,
  notification_important: true,
  warning: true,
  '4k': true,
  add_to_queue: true,
  airplay: true,
  album: true,
  art_track: true,
  av_timer: true,
  branding_watermark: true,
  call_to_action: true,
  closed_caption: true,
  control_camera: true,
  equalizer: true,
  explicit: true,
  fast_forward: true,
  fast_rewind: true,
  featured_play_list: true,
  featured_video: true,
  fiber_dvr: true,
  fiber_manual_record: true,
  fiber_new: true,
  fiber_pin: true,
  fiber_smart_record: true,
  forward_10: true,
  forward_30: true,
  forward_5: true,
  games: true,
  hd: true,
  hearing: true,
  high_quality: true,
  library_add: true,
  library_books: true,
  library_music: true,
  loop: true,
  mic: true,
  mic_none: true,
  mic_off: true,
  missed_video_call: true,
  movie: true,
  music_video: true,
  new_releases: true,
  not_interested: true,
  note: true,
  pause: true,
  pause_circle_filled: true,
  pause_circle_outline: true,
  play_arrow: true,
  play_circle_filled: true,
  play_circle_filled_white: true,
  play_circle_outline: true,
  playlist_add: true,
  playlist_add_check: true,
  playlist_play: true,
  queue: true,
  queue_music: true,
  queue_play_next: true,
  radio: true,
  recent_actors: true,
  remove_from_queue: true,
  repeat: true,
  repeat_one: true,
  replay: true,
  replay_10: true,
  replay_30: true,
  replay_5: true,
  shuffle: true,
  skip_next: true,
  skip_previous: true,
  slow_motion_video: true,
  snooze: true,
  sort_by_alpha: true,
  stop: true,
  subscriptions: true,
  subtitles: true,
  surround_sound: true,
  video_call: true,
  video_label: true,
  video_library: true,
  videocam: true,
  videocam_off: true,
  volume_down: true,
  volume_mute: true,
  volume_off: true,
  volume_up: true,
  web: true,
  web_asset: true,
  alternate_email: true,
  business: true,
  call: true,
  call_end: true,
  call_made: true,
  call_merge: true,
  call_missed: true,
  call_missed_outgoing: true,
  call_received: true,
  call_split: true,
  cancel_presentation: true,
  cell_wifi: true,
  chat: true,
  chat_bubble: true,
  chat_bubble_outline: true,
  clear_all: true,
  comment: true,
  contact_mail: true,
  contact_phone: true,
  contacts: true,
  desktop_access_disabled: true,
  dialer_sip: true,
  dialpad: true,
  domain_disabled: true,
  duo: true,
  email: true,
  forum: true,
  import_contacts: true,
  import_export: true,
  invert_colors_off: true,
  list_alt: true,
  live_help: true,
  location_off: true,
  location_on: true,
  mail_outline: true,
  message: true,
  mobile_screen_share: true,
  no_sim: true,
  pause_presentation: true,
  person_add_disabled: true,
  phone: true,
  phonelink_erase: true,
  phonelink_lock: true,
  phonelink_ring: true,
  phonelink_setup: true,
  portable_wifi_off: true,
  present_to_all: true,
  print_disabled: true,
  ring_volume: true,
  rss_feed: true,
  screen_share: true,
  sentiment_satisfied_alt: true,
  speaker_phone: true,
  stay_current_landscape: true,
  stay_current_portrait: true,
  stay_primary_landscape: true,
  stay_primary_portrait: true,
  stop_screen_share: true,
  swap_calls: true,
  textsms: true,
  unsubscribe: true,
  voicemail: true,
  vpn_key: true,
  add: true,
  add_box: true,
  add_circle: true,
  add_circle_outline: true,
  archive: true,
  backspace: true,
  ballot: true,
  block: true,
  clear: true,
  create: true,
  delete_sweep: true,
  drafts: true,
  file_copy: true,
  filter_list: true,
  flag: true,
  font_download: true,
  forward: true,
  gesture: true,
  how_to_reg: true,
  how_to_vote: true,
  inbox: true,
  link: true,
  link_off: true,
  low_priority: true,
  mail: true,
  markunread: true,
  move_to_inbox: true,
  next_week: true,
  outlined_flag: true,
  redo: true,
  remove: true,
  remove_circle: true,
  remove_circle_outline: true,
  reply: true,
  reply_all: true,
  report: true,
  report_off: true,
  save: true,
  save_alt: true,
  select_all: true,
  send: true,
  sort: true,
  text_format: true,
  unarchive: true,
  undo: true,
  waves: true,
  weekend: true,
  where_to_vote: true,
  access_alarm: true,
  access_alarms: true,
  access_time: true,
  add_alarm: true,
  add_to_home_screen: true,
  airplanemode_active: true,
  airplanemode_inactive: true,
  battery_20: true,
  battery_30: true,
  battery_50: true,
  battery_60: true,
  battery_80: true,
  battery_90: true,
  battery_alert: true,
  battery_charging_20: true,
  battery_charging_30: true,
  battery_charging_50: true,
  battery_charging_60: true,
  battery_charging_80: true,
  battery_charging_90: true,
  battery_charging_full: true,
  battery_full: true,
  battery_std: true,
  battery_unknown: true,
  bluetooth: true,
  bluetooth_connected: true,
  bluetooth_disabled: true,
  bluetooth_searching: true,
  brightness_auto: true,
  brightness_high: true,
  brightness_low: true,
  brightness_medium: true,
  data_usage: true,
  developer_mode: true,
  devices: true,
  dvr: true,
  gps_fixed: true,
  gps_not_fixed: true,
  gps_off: true,
  graphic_eq: true,
  location_disabled: true,
  location_searching: true,
  mobile_friendly: true,
  mobile_off: true,
  network_cell: true,
  network_wifi: true,
  nfc: true,
  screen_lock_landscape: true,
  screen_lock_portrait: true,
  screen_lock_rotation: true,
  screen_rotation: true,
  sd_storage: true,
  settings_system_daydream: true,
  signal_cellular_0_bar: true,
  signal_cellular_1_bar: true,
  signal_cellular_2_bar: true,
  signal_cellular_3_bar: true,
  signal_cellular_4_bar: true,
  signal_cellular_alt: true,
  signal_cellular_connected_no_internet_0_bar: true,
  signal_cellular_connected_no_internet_1_bar: true,
  signal_cellular_connected_no_internet_2_bar: true,
  signal_cellular_connected_no_internet_3_bar: true,
  signal_cellular_connected_no_internet_4_bar: true,
  signal_cellular_no_sim: true,
  signal_cellular_null: true,
  signal_cellular_off: true,
  signal_wifi_0_bar: true,
  signal_wifi_1_bar: true,
  signal_wifi_1_bar_lock: true,
  signal_wifi_2_bar: true,
  signal_wifi_2_bar_lock: true,
  signal_wifi_3_bar: true,
  signal_wifi_3_bar_lock: true,
  signal_wifi_4_bar: true,
  signal_wifi_4_bar_lock: true,
  signal_wifi_off: true,
  storage: true,
  usb: true,
  wallpaper: true,
  widgets: true,
  wifi_lock: true,
  wifi_tethering: true,
  add_comment: true,
  attach_file: true,
  attach_money: true,
  bar_chart: true,
  border_all: true,
  border_bottom: true,
  border_clear: true,
  border_color: true,
  border_horizontal: true,
  border_inner: true,
  border_left: true,
  border_outer: true,
  border_right: true,
  border_style: true,
  border_top: true,
  border_vertical: true,
  bubble_chart: true,
  drag_handle: true,
  format_align_center: true,
  format_align_justify: true,
  format_align_left: true,
  format_align_right: true,
  format_bold: true,
  format_clear: true,
  format_color_fill: true,
  format_color_reset: true,
  format_color_text: true,
  format_indent_decrease: true,
  format_indent_increase: true,
  format_italic: true,
  format_line_spacing: true,
  format_list_bulleted: true,
  format_list_numbered: true,
  format_list_numbered_rtl: true,
  format_paint: true,
  format_quote: true,
  format_shapes: true,
  format_size: true,
  format_strikethrough: true,
  format_textdirection_l_to_r: true,
  format_textdirection_r_to_l: true,
  format_underlined: true,
  functions: true,
  highlight: true,
  insert_chart: true,
  insert_chart_outlined: true,
  insert_comment: true,
  insert_drive_file: true,
  insert_emoticon: true,
  insert_invitation: true,
  insert_link: true,
  insert_photo: true,
  linear_scale: true,
  merge_type: true,
  mode_comment: true,
  monetization_on: true,
  money_off: true,
  multiline_chart: true,
  notes: true,
  pie_chart: true,
  publish: true,
  scatter_plot: true,
  score: true,
  short_text: true,
  show_chart: true,
  space_bar: true,
  strikethrough_s: true,
  table_chart: true,
  text_fields: true,
  title: true,
  vertical_align_bottom: true,
  vertical_align_center: true,
  vertical_align_top: true,
  wrap_text: true,
  attachment: true,
  cloud: true,
  cloud_circle: true,
  cloud_done: true,
  cloud_download: true,
  cloud_off: true,
  cloud_queue: true,
  cloud_upload: true,
  create_new_folder: true,
  folder: true,
  folder_open: true,
  folder_shared: true,
  cast: true,
  cast_connected: true,
  cast_for_education: true,
  computer: true,
  desktop_mac: true,
  desktop_windows: true,
  developer_board: true,
  device_hub: true,
  device_unknown: true,
  devices_other: true,
  dock: true,
  gamepad: true,
  headset: true,
  headset_mic: true,
  keyboard: true,
  keyboard_arrow_down: true,
  keyboard_arrow_left: true,
  keyboard_arrow_right: true,
  keyboard_arrow_up: true,
  keyboard_backspace: true,
  keyboard_capslock: true,
  keyboard_hide: true,
  keyboard_return: true,
  keyboard_tab: true,
  keyboard_voice: true,
  laptop: true,
  laptop_chromebook: true,
  laptop_mac: true,
  laptop_windows: true,
  memory: true,
  mouse: true,
  phone_android: true,
  phone_iphone: true,
  phonelink: true,
  phonelink_off: true,
  power_input: true,
  router: true,
  scanner: true,
  security: true,
  sim_card: true,
  smartphone: true,
  speaker: true,
  speaker_group: true,
  tablet: true,
  tablet_android: true,
  tablet_mac: true,
  toys: true,
  tv: true,
  videogame_asset: true,
  watch: true,
  add_a_photo: true,
  add_photo_alternate: true,
  add_to_photos: true,
  adjust: true,
  assistant: true,
  assistant_photo: true,
  audiotrack: true,
  blur_circular: true,
  blur_linear: true,
  blur_off: true,
  blur_on: true,
  brightness_1: true,
  brightness_2: true,
  brightness_3: true,
  brightness_4: true,
  brightness_5: true,
  brightness_6: true,
  brightness_7: true,
  broken_image: true,
  brush: true,
  burst_mode: true,
  camera: true,
  camera_alt: true,
  camera_front: true,
  camera_rear: true,
  camera_roll: true,
  center_focus_strong: true,
  center_focus_weak: true,
  collections: true,
  collections_bookmark: true,
  color_lens: true,
  colorize: true,
  compare: true,
  control_point: true,
  control_point_duplicate: true,
  crop: true,
  crop_16_9: true,
  crop_3_2: true,
  crop_5_4: true,
  crop_7_5: true,
  crop_din: true,
  crop_free: true,
  crop_landscape: true,
  crop_original: true,
  crop_portrait: true,
  crop_rotate: true,
  crop_square: true,
  dehaze: true,
  details: true,
  edit: true,
  exposure: true,
  exposure_neg_1: true,
  exposure_neg_2: true,
  exposure_plus_1: true,
  exposure_plus_2: true,
  exposure_zero: true,
  filter: true,
  filter_1: true,
  filter_2: true,
  filter_3: true,
  filter_4: true,
  filter_5: true,
  filter_6: true,
  filter_7: true,
  filter_8: true,
  filter_9: true,
  filter_9_plus: true,
  filter_b_and_w: true,
  filter_center_focus: true,
  filter_drama: true,
  filter_frames: true,
  filter_hdr: true,
  filter_none: true,
  filter_tilt_shift: true,
  filter_vintage: true,
  flare: true,
  flash_auto: true,
  flash_off: true,
  flash_on: true,
  flip: true,
  gradient: true,
  grain: true,
  grid_off: true,
  grid_on: true,
  hdr_off: true,
  hdr_on: true,
  hdr_strong: true,
  hdr_weak: true,
  healing: true,
  image: true,
  image_aspect_ratio: true,
  image_search: true,
  iso: true,
  landscape: true,
  leak_add: true,
  leak_remove: true,
  lens: true,
  linked_camera: true,
  looks: true,
  looks_3: true,
  looks_4: true,
  looks_5: true,
  looks_6: true,
  looks_one: true,
  looks_two: true,
  loupe: true,
  monochrome_photos: true,
  movie_creation: true,
  movie_filter: true,
  music_note: true,
  music_off: true,
  nature: true,
  nature_people: true,
  navigate_before: true,
  navigate_next: true,
  palette: true,
  panorama: true,
  panorama_fish_eye: true,
  panorama_horizontal: true,
  panorama_vertical: true,
  panorama_wide_angle: true,
  photo: true,
  photo_album: true,
  photo_camera: true,
  photo_filter: true,
  photo_library: true,
  photo_size_select_actual: true,
  photo_size_select_large: true,
  photo_size_select_small: true,
  picture_as_pdf: true,
  portrait: true,
  remove_red_eye: true,
  rotate_90_degrees_ccw: true,
  rotate_left: true,
  rotate_right: true,
  shutter_speed: true,
  slideshow: true,
  straighten: true,
  style: true,
  switch_camera: true,
  switch_video: true,
  tag_faces: true,
  texture: true,
  timelapse: true,
  timer: true,
  timer_10: true,
  timer_3: true,
  timer_off: true,
  tonality: true,
  transform: true,
  tune: true,
  view_comfy: true,
  view_compact: true,
  vignette: true,
  wb_auto: true,
  wb_cloudy: true,
  wb_incandescent: true,
  wb_iridescent: true,
  wb_sunny: true,
  '360': true,
  add_location: true,
  atm: true,
  beenhere: true,
  category: true,
  compass_calibration: true,
  departure_board: true,
  directions: true,
  directions_bike: true,
  directions_boat: true,
  directions_bus: true,
  directions_car: true,
  directions_railway: true,
  directions_run: true,
  directions_subway: true,
  directions_transit: true,
  directions_walk: true,
  edit_attributes: true,
  edit_location: true,
  ev_station: true,
  fastfood: true,
  flight: true,
  hotel: true,
  layers: true,
  layers_clear: true,
  local_activity: true,
  local_airport: true,
  local_atm: true,
  local_bar: true,
  local_cafe: true,
  local_car_wash: true,
  local_convenience_store: true,
  local_dining: true,
  local_drink: true,
  local_florist: true,
  local_gas_station: true,
  local_grocery_store: true,
  local_hospital: true,
  local_hotel: true,
  local_laundry_service: true,
  local_library: true,
  local_mall: true,
  local_movies: true,
  local_offer: true,
  local_parking: true,
  local_pharmacy: true,
  local_phone: true,
  local_pizza: true,
  local_play: true,
  local_post_office: true,
  local_printshop: true,
  local_see: true,
  local_shipping: true,
  local_taxi: true,
  map: true,
  money: true,
  my_location: true,
  navigation: true,
  near_me: true,
  not_listed_location: true,
  person_pin: true,
  person_pin_circle: true,
  pin_drop: true,
  place: true,
  rate_review: true,
  restaurant: true,
  restaurant_menu: true,
  satellite: true,
  store_mall_directory: true,
  streetview: true,
  subway: true,
  terrain: true,
  traffic: true,
  train: true,
  tram: true,
  transfer_within_a_station: true,
  transit_enterexit: true,
  trip_origin: true,
  zoom_out_map: true,
  apps: true,
  arrow_back: true,
  arrow_back_ios: true,
  arrow_downward: true,
  arrow_drop_down: true,
  arrow_drop_down_circle: true,
  arrow_drop_up: true,
  arrow_forward: true,
  arrow_forward_ios: true,
  arrow_left: true,
  arrow_right: true,
  arrow_upward: true,
  cancel: true,
  check: true,
  chevron_left: true,
  chevron_right: true,
  close: true,
  expand_less: true,
  expand_more: true,
  first_page: true,
  fullscreen: true,
  fullscreen_exit: true,
  last_page: true,
  menu: true,
  more_horiz: true,
  more_vert: true,
  refresh: true,
  subdirectory_arrow_left: true,
  subdirectory_arrow_right: true,
  unfold_less: true,
  unfold_more: true,
  adb: true,
  airline_seat_flat: true,
  airline_seat_flat_angled: true,
  airline_seat_individual_suite: true,
  airline_seat_legroom_extra: true,
  airline_seat_legroom_normal: true,
  airline_seat_legroom_reduced: true,
  airline_seat_recline_extra: true,
  airline_seat_recline_normal: true,
  bluetooth_audio: true,
  confirmation_number: true,
  disc_full: true,
  drive_eta: true,
  enhanced_encryption: true,
  event_available: true,
  event_busy: true,
  event_note: true,
  folder_special: true,
  live_tv: true,
  mms: true,
  more: true,
  network_check: true,
  network_locked: true,
  no_encryption: true,
  ondemand_video: true,
  personal_video: true,
  phone_bluetooth_speaker: true,
  phone_callback: true,
  phone_forwarded: true,
  phone_in_talk: true,
  phone_locked: true,
  phone_missed: true,
  phone_paused: true,
  power: true,
  power_off: true,
  priority_high: true,
  sd_card: true,
  sms: true,
  sms_failed: true,
  sync: true,
  sync_disabled: true,
  sync_problem: true,
  system_update: true,
  tap_and_play: true,
  time_to_leave: true,
  tv_off: true,
  vibration: true,
  voice_chat: true,
  vpn_lock: true,
  wc: true,
  wifi: true,
  wifi_off: true,
  ac_unit: true,
  airport_shuttle: true,
  all_inclusive: true,
  beach_access: true,
  business_center: true,
  casino: true,
  child_care: true,
  child_friendly: true,
  fitness_center: true,
  free_breakfast: true,
  golf_course: true,
  hot_tub: true,
  kitchen: true,
  meeting_room: true,
  no_meeting_room: true,
  pool: true,
  room_service: true,
  rv_hookup: true,
  smoke_free: true,
  smoking_rooms: true,
  spa: true,
  cake: true,
  domain: true,
  group: true,
  group_add: true,
  location_city: true,
  mood: true,
  mood_bad: true,
  notifications: true,
  notifications_active: true,
  notifications_none: true,
  notifications_off: true,
  notifications_paused: true,
  pages: true,
  party_mode: true,
  people: true,
  people_outline: true,
  person: true,
  person_add: true,
  person_outline: true,
  plus_one: true,
  poll: true,
  public: true,
  school: true,
  sentiment_dissatisfied: true,
  sentiment_satisfied: true,
  sentiment_very_dissatisfied: true,
  sentiment_very_satisfied: true,
  share: true,
  thumb_down_alt: true,
  thumb_up_alt: true,
  whatshot: true,
  check_box: true,
  check_box_outline_blank: true,
  indeterminate_check_box: true,
  radio_button_checked: true,
  radio_button_unchecked: true,
  star: true,
  star_border: true,
  star_half: true,
  toggle_off: true,
  toggle_on: true,
};

export type MaterialIconName = $Keys<typeof materialIconNames>;

type Props = {|
  children: MaterialIconName,
  color?: $PropertyType<ElementConfig<typeof Icon>, 'color'>,
  className?: string,
  style?: Object,
|};

export default function MaterialIcon({
  children,
  color,
  className,
  ...rest
}: Props) {
  return (
    <Icon className={className} color={color} {...rest}>
      {children}
    </Icon>
  );
}
